<template>
	<button
		type="button"
		:title="title"
		@click="_onClick"
	>
		<IconDark v-if="active" />
		<IconLight v-else />
		<span>{{ text }}</span>
	</button>
</template>

<script>
import IconDark from '@/assets/icons/dark.svg?inline';
import IconLight from '@/assets/icons/light.svg?inline';

export default {
	components: {
		IconDark,
		IconLight
	},
	data() {
		return {
			active: false
		};
	},
	computed: {
		text() {
			return `${this.active ? 'Dark' : 'Light'}`;
		},
		title() {
			return `Switch to ${this.active ? 'light' : 'dark'} theme`;
		}
	},
	watch: {
		active() {
			const
				value = (this.active) ? 'dark' : 'light'
			;

			document.body.classList.remove((this.active) ? 'light' : 'dark');
			document.body.classList.add(value);
			window.localStorage.setItem('theme', value);
		}
	},
	mounted() {
		const
			value = window.localStorage.getItem('theme')
		;

		if (value === 'dark' || !value && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.active = true;
		}
	},
	methods: {
		_onClick(e) {
			this.active = !this.active;
			e.target.blur();
		}
	}
};
</script>

<style lang="scss" scoped>
	span {
		transition:
			max-width var(--transition),
			margin var(--transition);
		display: block;
		overflow: hidden;
		max-width: 0;
	}

	button {
		--icon-size: 1.5rem;
		transition: background var(--transition);
		display: inline-flex;
		align-items: center;
		padding: 0 1rem;
		height: 2.5rem;
		border-radius: 1.25rem;
		font-size: rem(16);

		&:hover,
		&:focus {
			background-color: var(--color-field);

			span {
				margin-left: 0.5rem;
				width: auto;
				max-width: 100px;
			}
		}
	}
</style>
